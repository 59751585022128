//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import axios from 'axios';
import html2canvas from 'html2canvas';
import { mapGetters } from 'vuex';
import { getAuthFromCookie, saveTodayToCookie, getTodayFromCookie } from '@/utils/cookies.js';

import Hero from '@/components/ui/landing/main/Hero.vue';
import Banner from '@/components/ui/landing/main/Banner.vue';
import StreetMall from '@/components/ui/landing/main/StreetMall.vue';
import NewItem from '@/components/ui/landing/main/NewItem.vue';
import NewItemNonMember from '@/components/ui/landing/main/NewItemNonMember.vue';

export default {
	components: {
		Hero,
		Banner,
		StreetMall,
		NewItem,
		NewItemNonMember,
	},
	data() {
		return {
			loggedIn: false,
			popupDialog: false,
			popupImage: '',
			popupWidth: 500,
			popupHeight: 500,
			popupLinkType: '',
			popupLinkInfo: '',
			popupTodayCheck: false,
			siteTitle: '11',
			newItemPage: 0,
			fromRoute: null,
		};
	},
	computed: {
		...mapGetters('product', ['isPageSize', 'isTotalPages', 'isTotalItems', 'isSearchCondition']),
		...mapGetters('mall', ['isPartnerMallInfoData']),
		...mapGetters('auth', ['isLoggedInCheck']),
	},
	watch: {
		isLoggedInCheck(newValue) {
			if (newValue) {
				this.$nextTick(() => {
					if (this.$refs.child) {
						// this.$refs.child.$refs.infiniteLoader.stateChanger.reset();
						// console.log('watch==', this.newItemPage);
						this.$refs.child.setMyPage(this.newItemPage);
					}
				});
			}
		},
	},
	// eslint-disable-next-line vue/order-in-components
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fromRoute = from;
		});
	},

	async mounted() {
		// 도매상 정보 가져오기 (팝업 문제 해결)
		const mallId = process.env.partnerMallId;
		await this.$store.dispatch('mall/getPartnerMallById', mallId);
		// console.log('Home Index---mounted 실행!!');
		// 몰 정보는 layout에서 가져옵니다.
		const acessToken = getAuthFromCookie();
		this.$store.commit('auth/SET_TOKEN', acessToken);

		this.popupItems = this.isPartnerMallInfoData.mallPopupList;
		if (this.popupItems.length > 0) {
			const popupItem = this.isPartnerMallInfoData.mallPopupList[0];
			this.popupImage = popupItem.url;
			this.popupWidth = popupItem.width;
			this.popupHeight = popupItem.height;
			this.popupLinkType = popupItem.linkType;
			this.popupLinkInfo = popupItem.linkInfo;

			if (getTodayFromCookie() === 'todayChecked') {
				this.popupDialog = false;
			} else {
				this.popupDialog = true;
			}
		}
		// Product Store 초기화
		this.$store.commit('product/SET_ALL_INIT_DATA', ''); // 상품 목록 및 페이지, 조건 reset
		this.$store.commit('product/SET_STOCK', '판매');
		if (acessToken) {
			this.$nextTick(() => {
				if (this.$refs.child) {
					this.$refs.child.$refs.infiniteLoader.stateChanger.reset();
					this.newItemPage = 0;
				}
			});
		} else {
			this.nologinCallApi();
			this.newItemPage = 1;
		}
	},
	methods: {
		nologinCallApi() {
			if (this.isSearchCondition.stock !== '') {
				const params = this.isSearchCondition;
				params.page = 0;
				params.size = this.isPageSize;

				this.$store.dispatch('product/getSearchProduct', params);
			}
		},
		// infiniteLoader paging
		async callApi(newPage) {
			if (this.isSearchCondition.stock !== '') {
				const params = this.isSearchCondition;
				params.page = newPage;
				params.size = this.isPageSize;
				await this.$store.dispatch('product/getSearchProduct', params);

				const child = this.$refs.child;
				if (
					child &&
					child.$refs &&
					child.$refs.infiniteLoader &&
					child.$refs.infiniteLoader.stateChanger
				) {
					const stateChanger = child.$refs.infiniteLoader.stateChanger;
					if (newPage === this.isTotalPages) {
						stateChanger.complete();
					} else {
						stateChanger.loaded();
					}
				}
			}
		},
		popupLink() {
			if (this.popupLinkInfo.length > 0) {
				if (this.popupLinkType === 'URL') {
					const link = this.popupLinkInfo.includes('http://')
						? this.popupLinkInfo
						: 'http://' + this.popupLinkInfo;
					window.open(link, '_blank');
				}

				if (this.popupLinkType === '상품') {
					alert('상품 상세보기' + this.popupLinkInfo);
				}
			}
		},
		popupTodayClose() {
			if (this.popupTodayCheck) {
				saveTodayToCookie('todayChecked');
			}
			this.popupDialog = false;
		},
		onScreenShotClick() {
			const element = document.querySelector('#capture');
			// html2canvas(element).then(canvas => {
			// 	this.download(canvas, 'screenshot');
			// });
			html2canvas(element, { allowTaint: true, useCORS: false, proxy: '/_proxy' }).then(canvas => {
				this.download(canvas, 'screenshot');
			});
		},
		download(canvas, filename) {
			// create an "off-screen" anchor tag
			const a = document.createElement('a');

			// the key here is to set the download attribute of the a tag
			a.download = filename;

			// convert canvas content to data-uri for link. When download
			// attribute is set the content pointed to by link will be
			// pushed as "download" in HTML5 capable browsers
			a.href = canvas.toDataURL('image/png;base64');

			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},
	},
};
