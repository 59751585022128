//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';
import dayjs from 'dayjs';
export default {
	filters: {
		comma(val) {
			return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
		formatDate(val) {
			return dayjs(String(val)).format('YYYY-MM-DD');
		},
	},
	data() {
		return {
			baseUrl: `${process.env.baseURL}api/admin/items/displayFile?fileName=`,
			page: 0,
			confirmDialog: false,
		};
	},
	computed: {
		...mapGetters('auth', ['isLoggedInCheck', 'isUsername']),
		...mapGetters('product', ['isProduct']),
		...mapGetters('coupon', ['isCouponOrderApproval']),
	},
	async mounted() {
		const params = {
			username: this.isUsername,
			status: '승인',
			page: 0,
			size: 500,
		};
		await this.$store.dispatch('coupon/getSearchCouponOrderApproval', params);
	},
	methods: {
		...mapMutations('app', ['SET_PRODUCTDETAIL_DIALOG_VISIBLE', 'SET_SIGNIN_DIALOG_VISIBLE']),
		setMyPage(p) {
			console.log('mmmmm', p);
			this.page = p;
		},
		infiniteHandler($state) {
			this.$emit('fetchMore', this.page);
			this.page += 1;
		},
		async callProductDetailAPI(id) {
			if (!this.isLoggedInCheck) {
				this.SET_SIGNIN_DIALOG_VISIBLE(true);
			} else if (this.isCouponOrderApproval.length === 0) {
				this.confirmDialog = true;
			} else {
				await this.$store.dispatch('product/getProductById', id);
				// this.goProductDetail();
				this.onProductDetailDialog();
			}
		},
		goProductDetail() {
			this.$router.push('/shop/ProductDetailPage');
		},
		onProductDetailDialog() {
			this.SET_PRODUCTDETAIL_DIALOG_VISIBLE(true);
		},

		onCouponPage() {
			this.$router.push('/shop/CouponPage');
		},
	},
};
