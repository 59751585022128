//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';
import ShopDetailDialog from '@/components/ui/landing/dialog/ShopDetailDialog.vue';
export default {
	components: { ShopDetailDialog },
	data() {
		return {
			mandatory: false,
			showShopModal: false,
		};
	},
	computed: {
		...mapGetters('code', ['isCodes']),
		...mapGetters('mall', ['isMallStreet']),
		streetMallCodes() {
			return this.isCodes.filter(
				code => code.labels.includes('CODE_MALL') && code.codeName !== '입점몰',
			);
		},
	},
	methods: {
		...mapMutations('mall', ['SET_MALLSTREET']),
		async onStreetMall(street) {
			this.mandatory = true;
			this.SET_MALLSTREET(street);

			const params = this.getRequestParams(street, 1, 1000);
			await this.$store.dispatch('mall/getSearchShops', params);
			this.showShopModal = true;
		},
		getRequestParams(mallStreetName, page, pageSize) {
			const params = {};
			if (mallStreetName) {
				params.mallStreetName = mallStreetName;
			}
			if (page) {
				params.page = page - 1;
			}
			if (pageSize) {
				params.size = pageSize;
			}
			return params;
		},
	},
};
