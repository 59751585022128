//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			showing: false,
			items: [],
			imgHeight: 100,
		};
	},
	computed: {
		...mapGetters('mall', ['isPartnerMallInfoData', 'isBanners']),
	},
	methods: {
		goLink(item) {
			if (item.linkType === 'URL') {
				const link = item.linkInfo.includes('http://') ? item.linkInfo : 'http://' + item.linkInfo;
				window.open(link, '_blank');
			}
			if (item.linkType === '상품') {
				alert('상품 상세보기' + item.linkInfo);
			}
		},
	},
};
