//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			dialog: false,

			shopName: '',
			selectedShops: [],
			// 페이지 이동에 사용 setPage 변수 사용
			// page: 0,
			setPage: 0,
			pageSize: 1000,
		};
	},
	computed: {
		...mapGetters('mall', ['isMallStreet', 'isStreetSearchShops']),
		show: {
			get() {
				return this.visible;
			},
			set(value) {
				this.shopName = '';
				if (!value) {
					this.$emit('close');
				}
			},
		},
	},

	methods: {
		...mapMutations('app', ['SET_SHOP_DIALOG_VISIBLE']),
		handlePageChange(value) {
			this.setPage = value;
			this.fetchShops();
		},
		handleClick(event) {
			const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
			if (!isMobile) {
				event.preventDefault();
			}
		},
		goProducts(shopId) {
			// Product Store 초기화
			this.$store.commit('product/SET_INIT_DATA', '');
			this.$store.commit('product/SET_STOCK', '판매'); // 판매 상품
			const dateParam = { startDate: '', endDate: '' };
			this.$store.commit('product/SET_CREATEDATE', dateParam); // 등록 일자
			const priceParam = { startPrice: '', endPrice: '' };
			this.$store.commit('product/SET_STANDARDPRICE', priceParam); // 기준가
			this.$store.commit('product/SET_ITEMNAME', ''); // 상품명
			this.$store.commit('product/SET_PARTNERSHOPID', shopId); // 도매상
			this.$router.push('/shop/RegularProducts');
		},
		fetchShops() {
			const params = this.getRequestParams(this.shopName, this.setPage, this.pageSize);
			this.$store.dispatch('mall/getSearchShops', params);
		},
		getRequestParams(shopName, page, pageSize) {
			const params = {};

			params.mallStreetName = this.isMallStreet;
			if (shopName) {
				params.shopName = shopName;
			}
			if (page) {
				params.page = page - 1;
			}
			if (pageSize) {
				params.size = pageSize;
			}
			return params;
		},
	},
};
